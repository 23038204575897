import React from 'react';
import SignIn from '../components/Auth/SignIn';
import SignUp from '../components/Auth/SignUp';

const AuthPage: React.FC = () => {
    const [isSignUp, setIsSignUp] = React.useState(false);

    return (
    <div>
        {isSignUp ? <SignUp /> : <SignIn />}
        <button onClick={() => setIsSignUp(!isSignUp)}>
        Switch to {isSignUp ? 'Sign In' : 'Sign Up'}
        </button>
    </div>
    );
}

export default AuthPage;
