import React from 'react';

const Header = () => {
    return (
    <header>
        <h1>This is the header component</h1>
    </header>
    );
};

export default Header;
