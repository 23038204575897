import React, { useState } from 'react';

const SignUp: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Call authentication API for sign up
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-md mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-2xl font-bold mb-4">Sign Up</h2>
            <input
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full px-4 py-2 mb-2 border rounded-lg"
            />
            <input
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full px-4 py-2 mb-2 border rounded-lg"
            />
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-2">
                Sign Up
            </button>
            <button type="button" className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2">
                Sign Up with Google
            </button>
            <button type="button" className="bg-gray-800 text-white px-4 py-2 rounded-lg mr-2">
                Sign Up with GitHub
            </button>
            <button type="button" className="bg-gray-700 text-white px-4 py-2 rounded-lg">
                Sign Up with GitLab
            </button>
            <button type="button" className="bg-gray-600 text-white px-4 py-2 rounded-lg">
                Sign Up with Bitbucket
            </button>
        </form>
    );
};

export default SignUp;
